import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './Plans.css';
import { loadStripe } from '@stripe/stripe-js';
import { auth } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import LogInButton from './LogInButton';
import { getUserSubscription } from './supabase';

// Use test key for development, live key for production
const stripePromise = loadStripe(
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_STRIPE_TEST_PUBLISHABLE_KEY
    : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
);

export const plans = [
  {
    id: 'plan_starter',
    name: "Ping Essentials",
    price: "$0",
    period: "forever",
    features: [
      "Up to 3 active notifications",
      "Notifies within 24 hours",
      "Email support"
    ],
    notificationLimit: 3
  },
  {
    id: 'plan_pro',
    name: "Ping Pro",
    price: "$3.99",
    period: "per month",
    features: [
      "Up to 20 active notifications",
      "Notifies within 4 hours",
      "Priority support",
    ],
    notificationLimit: 20
  },
  {
    id: 'plan_master',
    name: "Ping Master",
    price: "$19.99",
    period: "per month",
    features: [
      "Unlimited active notifications",
      "Notifies instantly",
      "Priority support and feature previews",
    ],
    notificationLimit: Infinity
  }
];

function Plans({ inModal = false, onPlanSelect = null }) {
  const [user] = useAuthState(auth);
  const [loginButtonIndex, setLoginButtonIndex] = useState(null);
  const [currentPlanState, setCurrentPlanState] = useState('plan_starter');

  useEffect(() => {
    const fetchCurrentPlan = async () => {
      if (user) {
        try {
          const subscription = await getUserSubscription(user.uid);
          setCurrentPlanState(subscription?.plan_id || 'plan_starter');
        } catch (error) {
          console.error("Error fetching user subscription:", error);
          setCurrentPlanState('plan_starter');
        }
      }
    };

    fetchCurrentPlan();
  }, [user]);

  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  const handleSubscription = async (planId) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }

      // Skip checkout for 'plan_starter'
      if (planId === 'plan_starter') {
        // TODO: Implement logic to downgrade user's plan to 'plan_starter'
        console.log('Switching to Starter Plan');
        return;
      }

      const stripe = await stripePromise;
      const response = await fetch(`${process.env.REACT_APP_API_URL}/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          planId, 
          userId: user.uid,
          mode: process.env.NODE_ENV,
          cancelUrl: window.location.href,
        }),
      });
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      const result = await stripe.redirectToCheckout({
        sessionId: data.sessionId,
      });
      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    }
  };

  const handleLoginPrompt = (index) => {
    setLoginButtonIndex(index);
  };

  return (
    <div className={`plans-container ${inModal ? 'in-modal' : ''}`}>
      {!inModal && (
        <>
          <motion.h1 {...fadeIn}>Choose Your Plan</motion.h1>
          <motion.p {...fadeIn} transition={{ delay: 0.2 }} className="plans-description">
            Select the plan that best fits your notification needs
          </motion.p>
        </>
      )}
      <div className="plans-grid">
        {plans.map((plan, index) => (
          <motion.div
            key={plan.name}
            className={`plan-card ${plan.name === 'Pro' ? 'featured' : ''}`}
            {...fadeIn}
            transition={{ delay: 0.4 + index * 0.2 }}
          >
            <h2>{plan.name}</h2>
            <div className="price">
              <span className="amount">{plan.price}</span>
              <span className="period"> {plan.period}</span>
            </div>
            <ul>
              {plan.features.map((feature, i) => (
                <li key={i}>{feature}</li>
              ))}
            </ul>
            {user ? (
              currentPlanState === plan.id ? (
                <button className="current-plan-btn" disabled>
                  Your Current Plan
                </button>
              ) : (
                <button className="change-plan-btn" onClick={() => handleSubscription(plan.id)}>
                  Change to this Plan
                </button>
              )
            ) : (
              <div className="plans-login-container">
                {loginButtonIndex === index ? (
                  <LogInButton className="login-button small-login-button"/>
                ) : plan.id === 'plan_starter' ? (
                  <button className="try-now-btn" onClick={() => handleLoginPrompt(index)}>
                    Try Now - Ready to Use!
                  </button>
                ) : (
                  <button className="login-to-select-btn" onClick={() => handleLoginPrompt(index)}>
                    Sign in to Choose Plan
                  </button>
                )}
              </div>
            )}
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default Plans;