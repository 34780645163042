export async function validateNotificationRequest(text, useActualImplementation = true) {
    if (!useActualImplementation) {
        // Fake implementation for testing
        return new Promise((resolve) => {
            setTimeout(() => {
                const randomResult = Math.random() < 0.5;
                console.log(`Fake validation result for "${text}": ${randomResult ? 'yes' : 'no'}`);
                resolve({
                    is_valid: randomResult,
                    invalid_reason: randomResult ? null : "This notification request doesn't seem valid or reasonable. Please try rephrasing your request.",
                    suggested_method: randomResult ? "Fake tracking method" : null
                });
            }, 3000);
        });
    }

    // Actual implementation using backend API
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/validate-notification`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error("Error validating notification request:", error);
        return {
            is_valid: false,
            invalid_reason: "An error occurred while processing the request",
            suggested_method: null
        };
    }
}