import React, { useEffect } from 'react';
import { Container, Typography, Box } from '@mui/material';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Box className="privacy-policy-container">
        <Typography variant="h4" gutterBottom>
          Privacy Policy for PingPops
        </Typography>
        <Box component="article">
          <Typography variant="body1" paragraph>
            Last updated: Sept 06, 2024
          </Typography>

          <Typography variant="h6" gutterBottom>
            1. Introduction
          </Typography>
          <Typography variant="body1" paragraph>
            Welcome to PingPops. We are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our web application.
          </Typography>

          <Typography variant="h6" gutterBottom>
            2. Information We Collect
          </Typography>
          <Typography variant="body1" paragraph>
            We collect personal information that you provide to us, such as:
            <ul>
              <li>Email address</li>
              <li>Preferences and settings</li>
              <li>Any messages or communications you send to our support team</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom>
            3. How We Use Your Information
          </Typography>
          <Typography variant="body1" paragraph>
            We use the information we collect to:
            <ul>
              <li>Provide, operate, and maintain our web application</li>
              <li>Send notifications based on your preferences</li>
              <li>Improve, personalize, and expand our web application</li>
              <li>Understand and analyze how you use our web application</li>
              <li>Develop new products, services, features, and functionality</li>
              <li>Communicate with you for customer service, updates, and marketing purposes</li>
              <li>Find and prevent fraud</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom>
            4. Third-Party Services
          </Typography>
          <Typography variant="body1" paragraph>
            We use third-party services that may collect information used to identify you.
            Please refer to their respective privacy policies for more information on how they handle your data.
          </Typography>

          <Typography variant="h6" gutterBottom>
            5. Data Security
          </Typography>
          <Typography variant="body1" paragraph>
            We implement appropriate technical and organizational security measures to protect your personal information. However, please note that no method of transmission over the Internet or electronic storage is 100% secure.
          </Typography>

          <Typography variant="h6" gutterBottom>
            6. Your Data Protection Rights
          </Typography>
          <Typography variant="body1" paragraph>
            Depending on your location, you may have certain rights regarding your personal information, such as the right to access, correct, or delete your data. Please contact us to exercise these rights.
          </Typography>

          <Typography variant="h6" gutterBottom>
            7. Changes to This Privacy Policy
          </Typography>
          <Typography variant="body1" paragraph>
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.
          </Typography>

          <Typography variant="h6" gutterBottom>
            8. Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions about this Privacy Policy, please contact us by <a href="mailto:hi@pingpops.com">email</a>.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default PrivacyPolicy;