import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from './firebase';
import { useNavigate } from 'react-router-dom';
import { 
  Container, Typography, Grid, Paper, TextField, Select, MenuItem, 
  Button, List, ListItem, ListItemText, ListItemSecondaryAction, 
  IconButton, Collapse, Box, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Alert
} from '@mui/material';
import { Delete, Edit, PlayArrow, Pause, ExpandMore, ExpandLess } from '@mui/icons-material';
import './Console.css';
import Plans, { plans } from './Plans';
import { getUserSubscription, fetchNotifications, addNotification, updateNotification, deleteNotification, countActiveNotifications, addInvalidNotifyRequestAttempt } from './supabase';
import { validateNotificationRequest } from './validationService';
import SupportForm from './SupportForm';

function Console() {
    const [user, setUser] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const [newNotification, setNewNotification] = useState({ text: '', email: '', frequency: 'immediately' });
    const [isFormExpanded, setIsFormExpanded] = useState(false);
    const [editingIndex, setEditingIndex] = useState(null);
    const [showPlans, setShowPlans] = useState(false);
    const [subscription, setSubscription] = useState({ plan_id: 'plan_starter', status: 'active' });
    const [activeNotificationsCount, setActiveNotificationsCount] = useState(0);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const [upgradeMessage, setUpgradeMessage] = useState('');
    const [isValidating, setIsValidating] = useState(false);
    const [validationError, setValidationError] = useState('');
    const [showSupport, setShowSupport] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Initialize Google Analytics
        ReactGA.initialize('G-GM58SHFDSW');
        
        // Send pageview
        ReactGA.send({ hitType: "pageview", page: "/console" });

        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                setNewNotification(prev => ({ ...prev, email: currentUser.email }));
                try {
                    const userSubscription = await getUserSubscription(currentUser.uid);
                    setSubscription(userSubscription || { plan_id: 'plan_starter', status: 'active' });
                    const userNotifications = await fetchNotifications(currentUser.uid);
                    setNotifications(userNotifications);
                    const activeCount = await countActiveNotifications(currentUser.uid);
                    setActiveNotificationsCount(activeCount);
                } catch (error) {
                    console.error("Error fetching user data:", error);
                    setSubscription({ plan_id: 'plan_starter', status: 'active' });
                }

                // Track user login
                ReactGA.event({
                    category: 'User',
                    action: 'Logged In',
                    label: currentUser.email
                });
            } else {
                navigate('/');
            }
        });
    
        return () => unsubscribe();
    }, [navigate]);

    useEffect(() => {
        const pendingNotification = localStorage.getItem('pendingNotification');
        if (pendingNotification) {
            const { text, email } = JSON.parse(pendingNotification);
            setNewNotification(prev => ({ ...prev, text, email }));
            setIsFormExpanded(true);
            localStorage.removeItem('pendingNotification');
        }
    }, []);

    const getNotificationLimit = (planId) => {
        const plan = plans.find(p => p.id === planId);
        return plan ? plan.notificationLimit : 3; // Default to 3 if plan not found
    };

    const showUpgradePrompt = (currentLimit) => {
        const nextPlan = plans.find(plan => plan.notificationLimit > currentLimit);
        const message = (
            <>
                <Typography variant="body1" gutterBottom>
                    Your current plan allows for <Box component="span" fontWeight="bold">{currentLimit}</Box> active notifications.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Ready to level up? <Box component="span" fontWeight="bold">{nextPlan.name}</Box> plan offers <Box component="span" fontWeight="bold">{nextPlan.notificationLimit === Infinity ? 'unlimited' : nextPlan.notificationLimit}</Box> active notifications{nextPlan.notificationLimit === Infinity ? ' without limits' : ''}, 
                    along with {nextPlan.features[1].toLowerCase()} and {nextPlan.features[2].toLowerCase()}.
                </Typography>
            </>
        );
        setUpgradeMessage(message);
        setShowUpgradeModal(true);
    };

    const handleNotificationTextChange = (e) => {
        setNewNotification({ ...newNotification, text: e.target.value });
        // Clear the validation error when the user starts editing
        if (validationError) {
            setValidationError('');
        }
    };

    const handleAddNotification = async () => {
        if (!user) return;

        const notificationLimit = getNotificationLimit(subscription.plan_id);

        if (activeNotificationsCount >= notificationLimit && editingIndex === null) {
            showUpgradePrompt(notificationLimit);
            return;
        }

        try {
            setValidationError('');
            // Validate the notification request
            setIsValidating(true);
            const validationResult = await validateNotificationRequest(newNotification.text);
            setIsValidating(false);
            if (!validationResult.is_valid) {
                setValidationError(validationResult.invalid_reason || "Please rephrase your request to be more specific or trackable.");
                
                try {
                    await addInvalidNotifyRequestAttempt({
                        user_id: user.uid,
                        text: newNotification.text,
                        email: newNotification.email,
                        frequency: newNotification.frequency,
                        invalid_reason: validationResult.invalid_reason
                    });
                } catch (error) {
                    console.error("Error adding invalid notification request:", error);
                }
                
                return;
            }
            const notificationData = {
                ...newNotification,
                suggested_method: validationResult.suggested_method
            };

            if (editingIndex !== null) {
                // Update existing notification
                const updatedNotification = await updateNotification(notifications[editingIndex].id, notificationData);
                const updatedNotifications = [...notifications];
                updatedNotifications[editingIndex] = updatedNotification;
                setNotifications(updatedNotifications);
            } else {
                // Add new notification
                const addedNotification = await addNotification(user.uid, notificationData);
                setNotifications([...notifications, addedNotification]);
            }

            setNewNotification({ text: '', email: user.email, frequency: 'immediately' });
            setIsFormExpanded(false);
            setEditingIndex(null);

            // Update the active notifications count
            const newActiveCount = await countActiveNotifications(user.uid);
            setActiveNotificationsCount(newActiveCount);
        } catch (error) {
            setIsValidating(false);
            setValidationError("An error occurred while processing your request. Please try again.");
            console.error("Error adding/updating notification:", error);
            ReactGA.exception({
                description: 'Error adding/updating notification',
                fatal: false
            });
        }
    };

    const handlePauseRestartNotification = async (notificationId, currentStatus) => {
        if (!user) return;

        const newStatus = currentStatus === 'Paused' ? 'Active' : 'Paused';

        if (newStatus === 'Active') {
            const notificationLimit = getNotificationLimit(subscription.plan_id);
            if (activeNotificationsCount >= notificationLimit) {
                showUpgradePrompt(notificationLimit);
                return;
            }
        }

        try {
            const updatedNotification = await updateNotification(notificationId, { status: newStatus });
            setNotifications(prevNotifications => 
                prevNotifications.map(notification => 
                    notification.id === notificationId ? updatedNotification : notification
                )
            );
    
            // Update the active notifications count
            const newActiveCount = await countActiveNotifications(user.uid);
            setActiveNotificationsCount(newActiveCount);
        } catch (error) {
            console.error("Error updating notification status:", error);
        }
    };

    const handleDeleteNotification = async (notificationId) => {
        if (!user) return;

        try {
            await deleteNotification(user.uid, notificationId);
            // Refresh the notifications list
            const updatedNotifications = await fetchNotifications(user.uid);
            setNotifications(updatedNotifications);
            setEditingIndex(null);

            // Update the active notifications count
            const newActiveCount = await countActiveNotifications(user.uid);
            setActiveNotificationsCount(newActiveCount);
        } catch (error) {
            console.error("Error deleting notification:", error);
            //TODO: show an error message to the user here
        }
    };

    const handleEditNotification = (index) => {
        setNewNotification(notifications[index]);
        setEditingIndex(index);
        setIsFormExpanded(true);
    };

    const handleSignOut = () => {
        signOut(auth).then(() => {
            navigate('/');
        }).catch((error) => {
            console.error("Error signing out: ", error);
        });
    };

    const handleSupportSubmit = async (supportData) => {
        setShowSupport(false);
    };

    if (!user) return null;

    return (
        <Container maxWidth="md" className="console-container" sx={{ mt: 4, mb: 4, px: { xs: 2, sm: 3 } }}>
            <Typography variant="h4" className="console-title" gutterBottom sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' } }}>
                PingPops Dashboard
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className="notification-paper" sx={{ p: { xs: 2, sm: 3 } }}>
                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', alignItems: { xs: 'stretch', sm: 'center' }, mb: 2 }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: { xs: 1, sm: 0 } }}>
                                {isFormExpanded ? 'Add New Notification' : 'Notifications'}
                            </Typography>
                            <Button
                                variant="contained"
                                className={`form-button ${isFormExpanded ? '' : 'add-button'}`}
                                startIcon={isFormExpanded ? <ExpandLess /> : <ExpandMore />}
                                onClick={() => setIsFormExpanded(!isFormExpanded)}
                                fullWidth={window.innerWidth < 600}
                            >
                                {isFormExpanded ? 'Close' : 'Add Notification'}
                            </Button>
                        </Box>
                        <Collapse in={isFormExpanded}>
                            <Box sx={{ mb: 2 }}>
                                <TextField
                                    fullWidth
                                    label="Notify me when..."
                                    variant="outlined"
                                    value={newNotification.text}
                                    onChange={handleNotificationTextChange}
                                    sx={{ mb: 2, '& .MuiOutlinedInput-root': { borderRadius: '12px' } }}
                                />
                                <TextField
                                    fullWidth
                                    label="Email"
                                    variant="outlined"
                                    type="email"
                                    value={newNotification.email}
                                    onChange={(e) => setNewNotification({ ...newNotification, email: e.target.value })}
                                    sx={{ mb: 2, '& .MuiOutlinedInput-root': { borderRadius: '12px' } }}
                                />
                                <Select
                                    fullWidth
                                    value={newNotification.frequency}
                                    onChange={(e) => setNewNotification({ ...newNotification, frequency: e.target.value })}
                                    variant="outlined"
                                    sx={{ mb: 2, '& .MuiOutlinedInput-root': { borderRadius: '12px' } }}
                                >
                                    <MenuItem value="immediately">ASAP</MenuItem>
                                    <MenuItem value="daily">Daily Digest</MenuItem>
                                    <MenuItem value="weekly">Weekly Digest</MenuItem>
                                </Select>
                                {validationError && (
                                    <Alert severity="error" sx={{ mb: 2, borderRadius: '12px' }}>
                                        {validationError}
                                    </Alert>
                                )}
                                <Button 
                                    variant="contained" 
                                    onClick={handleAddNotification}
                                    className="form-button add-button"
                                    disabled={isValidating}
                                    startIcon={isValidating ? <CircularProgress size={20} color="inherit" /> : null}
                                >
                                    {isValidating ? 'Processing...' : (editingIndex !== null ? 'Update' : 'Add')}
                                </Button>
                            </Box>
                        </Collapse>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className="notification-paper" sx={{ p: { xs: 2, sm: 3 } }}>
                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                            Your Notifications
                        </Typography>
                        <List>
                            {notifications.map((notification, index) => (
                                <ListItem key={index} className="notification-item" sx={{ flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'flex-start', sm: 'center' } }}>
                                    <ListItemText
                                        primary={<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{notification.text}</Typography>}
                                        secondary={
                                            <React.Fragment>
                                                <Typography component="span" variant="body2" sx={{ display: 'block' }}>
                                                    Email: {notification.email}
                                                </Typography>
                                                <Typography component="span" variant="body2" sx={{ display: 'block' }}>
                                                    Frequency: {notification.frequency}
                                                </Typography>
                                                <Typography component="span" variant="body2" sx={{ display: 'block' }}>
                                                    Status: {notification.status || 'Active'}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                    <ListItemSecondaryAction sx={{ position: { xs: 'static', sm: 'absolute' }, mt: { xs: 1, sm: 0 }, display: 'flex', gap: 1 }}>
                                        <IconButton 
                                            edge="end" 
                                            aria-label="pause-restart" 
                                            onClick={() => handlePauseRestartNotification(notification.id, notification.status)}
                                            className="pause-restart-button"
                                            color={notification.status === 'Paused' ? 'success' : 'warning'}
                                        >
                                            {notification.status === 'Paused' ? <PlayArrow /> : <Pause />}
                                        </IconButton>
                                        <IconButton 
                                            edge="end" 
                                            aria-label="edit" 
                                            onClick={() => handleEditNotification(index)} 
                                            className="edit-button"
                                            color="info"
                                        >
                                            <Edit />
                                        </IconButton>
                                        <IconButton 
                                            edge="end" 
                                            aria-label="delete" 
                                            onClick={() => handleDeleteNotification(notification.id)} 
                                            className="delete-button"
                                            color="error"
                                        >
                                            <Delete />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                </Grid>
                <Grid item xs={12} sx={{ mt: 3, display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'center', gap: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setShowPlans(!showPlans)}
                        className="upgrade-plan-button"
                        fullWidth={window.innerWidth < 600}
                    >
                        {showPlans ? 'Close Plans' : 'Your Plan'}
                    </Button>
                    <Button
                        variant="contained"
                        color="info"
                        onClick={() => setShowSupport(!showSupport)}
                        className="contact-support-button"
                        fullWidth={window.innerWidth < 600}
                    >
                        {showSupport ? 'Close Contact' : 'Contact Us'}
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleSignOut}
                        className="sign-out-button"
                        fullWidth={window.innerWidth < 600}
                    >
                        Sign Out
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={showPlans}>
                        <Paper className="plans-paper" sx={{ p: { xs: 2, sm: 3 }, mt: 2 }}>
                            <Plans />
                        </Paper>
                    </Collapse>
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={showSupport}>
                        <Paper className="support-paper" sx={{ p: { xs: 2, sm: 3 }, mt: 2 }}>
                            <SupportForm onSubmit={handleSupportSubmit} userEmail={user?.email} />
                        </Paper>
                    </Collapse>
                </Grid>
            </Grid>
            
            <Dialog open={showUpgradeModal} onClose={() => setShowUpgradeModal(false)} maxWidth="md" fullWidth>
                <DialogTitle>Upgrade Your Plan</DialogTitle>
                <DialogContent>
                    {upgradeMessage}
                    <Plans 
                        inModal={true} 
                        onPlanSelect={() => setShowUpgradeModal(false)} 
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowUpgradeModal(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default Console;