import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

export const createUser = async (user) => {
    // First, try to get the existing user
    const { data: existingUser } = await supabase
      .from('users')
      .select('*')
      .eq('id', user.uid)
      .single();

    const isNewUser = !existingUser;

    // Now perform the upsert
    const { data, error } = await supabase
      .from('users')
      .upsert(
        { 
          id: user.uid,
          email: user.email,
          updated_at: new Date().toISOString()
        },
        { onConflict: 'id', returning: 'representation' }
      );
    
    if (error) throw error;
  
    return { user: data?.[0] || user, isNewUser };
};

export const getUserSubscription = async (userId) => {
  const { data, error } = await supabase
    .from('subscriptions')
    .select('*')
    .eq('user_id', userId)
    .single();

  if (error && error.code !== 'PGRST116') throw error;
  return data;
};

export const fetchNotifications = async (userId) => {
  const { data, error } = await supabase
    .from('notifications')
    .select('*')
    .eq('user_id', userId)
    .eq('is_deleted', false);
  if (error) {
    throw error;
  }
  return data;
};

export const addNotification = async (userId, notificationData) => {
  const { data, error } = await supabase
    .from('notifications')
    .insert({
      ...notificationData,
      user_id: userId,
      status: 'Active',
      suggested_method: notificationData.suggested_method
    })
    .select()
    .single();

  if (error) throw error;
  return data;
};

export const updateNotification = async (notificationId, updateData) => {
  const { data, error } = await supabase
    .from('notifications')
    .update({
      ...updateData,
      suggested_method: updateData.suggested_method
    })
    .eq('id', notificationId)
    .select()
    .single();

  if (error) throw error;
  if (!data) throw new Error('No notification found with the given ID');
  return data;
};

export const deleteNotification = async (userId, notificationId) => {
    const { error } = await supabase
        .from('notifications')
        .update({ is_deleted: true })
        .eq('id', notificationId)
        .eq('user_id', userId);

    if (error) {
        throw error;
    }
};

export const countActiveNotifications = async (userId) => {
  const { count, error } = await supabase
    .from('notifications')
    .select('*', { count: 'exact', head: true })
    .eq('user_id', userId)
    .eq('is_deleted', false)
    .eq('status', 'Active');

  if (error) throw error;
  return count;
};

export const addInvalidNotifyRequestAttempt = async (invalidRequest) => {
    const { data, error } = await supabase
        .from('invalid_notify_attempts')
        .insert([
            {
                user_id: invalidRequest.user_id,
                text: invalidRequest.text,
                email: invalidRequest.email,
                frequency: invalidRequest.frequency,
                invalid_reason: invalidRequest.invalid_reason,
                created_at: new Date().toISOString()
            }
        ]);

    if (error) throw error;
    return data;
};