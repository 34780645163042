import React, { useState } from 'react';
import { TextField, Button, Box, Select, MenuItem, InputLabel, FormControl, Typography, Fade } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

function SupportForm({ onSubmit, userEmail }) {
    const [requestType, setRequestType] = useState('question');
    const [message, setMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [statusMessage, setStatusMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = { requestType, message, email: userEmail };
        
        try {
            // Send email to support account
            const response = await fetch(`${process.env.REACT_APP_API_URL}/add_support_request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Failed to submit the request');
            }

            // Update state for success
            setIsSuccess(true);
            setStatusMessage("We've received your request and will get back to you soon.");
            setShowMessage(true);

            // Clear form fields after successful submission
            setTimeout(async () => {
                try {
                    await onSubmit(formData);
                } catch (error) {
                    console.error('Error in delayed onSubmit:', error);
                    // Optionally handle this error, e.g., by showing a new message
                }

                // Reset form fields
                setRequestType('question');
                setMessage('');
                setShowMessage(false);
            }, 4000);
        } catch (error) {
            console.error('Error submitting support form:', error);
            setIsSuccess(false);
            setStatusMessage('Oops! Something went wrong. Please try again.');
            setShowMessage(true);
            setTimeout(() => setShowMessage(false), 4000); 
        }
    };

    if (showMessage) {
        return (
            <Fade in={showMessage}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', textAlign: 'center' }}>
                    {isSuccess ? (
                        <CheckCircleOutlineIcon color="success" sx={{ fontSize: 60, mb: 2 }} />
                    ) : (
                        <ErrorOutlineIcon color="error" sx={{ fontSize: 60, mb: 2 }} />
                    )}
                    <Typography variant="h6">{statusMessage}</Typography>
                </Box>
            </Fade>
        );
    }

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="request-type-label">How can we help?</InputLabel>
                <Select
                    labelId="request-type-label"
                    value={requestType}
                    label="How can we help?"
                    onChange={(e) => setRequestType(e.target.value)}
                    required
                >
                    <MenuItem value="bug-report">Report an issue</MenuItem>
                    <MenuItem value="question">Ask a question</MenuItem>
                    <MenuItem value="feature-request">Suggest a feature</MenuItem>
                    <MenuItem value="account-help">Account help</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                </Select>
            </FormControl>
            <TextField
                fullWidth
                label="Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
                multiline
                rows={4}
                sx={{ mb: 2 }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Button type="submit" variant="contained" color="primary">
                    Submit
                </Button>
                <Box component="span">
                    or{' '}
                    <Box
                        component="a"
                        href="mailto:hi@pingpops.com"
                        sx={{ textDecoration: 'none', color: 'primary.main' }}
                    >
                        email us
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default SupportForm;