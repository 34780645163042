import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './Home';
import Console from './Console';
import PrivacyPolicy from './PrivacyPolicy';


function App() {
  return (
    <div className="App">
       <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/console" element={<Console />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
    </div>
  );
}

export default App;