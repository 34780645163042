import React, { useState, useCallback, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { TextField, Button, Typography, InputAdornment } from '@mui/material';
import Plans from './Plans';
import './App.css';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import LogInButton from './LogInButton';
import ReactGA from 'react-ga4';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import EmailIcon from '@mui/icons-material/Email';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase';

const FADE_IN = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 }
};

const iconSx = {
  color: 'var(--primary-color)',
  opacity: 0.3,
};

const Step = ({ title, text, delay }) => (
  <motion.div
    className="step"
    {...FADE_IN}
    transition={{ delay }}
  >
    <div className="step-content">
      <h3>{title}</h3>
      <p>{text}</p>
    </div>
  </motion.div>
);

const Card = ({ title, desc, examples }) => (
    <motion.div
      className="card"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <h3>{title}</h3>
      <p>{desc}</p>
      <ul className="examples">
        {examples.map((example, index) => (
          <li key={index} className="example-item">
            <LightbulbOutlinedIcon className="example-icon" fontSize="small" />
            {example}
          </li>
        ))}
      </ul>
    </motion.div>
  );

function Home() {
  const [showPlans, setShowPlans] = useState(false);
  const [notificationText, setNotificationText] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({ notificationText: '', email: '' });
  const [isFormValid, setIsFormValid] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  const togglePlans = useCallback((e) => {
    e.preventDefault();
    setShowPlans(prev => !prev);
    ReactGA.event({
      category: 'Home Page Plans',
      action: 'Home - Plans Toggled',
    });
  }, []);

  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize('G-GM58SHFDSW');
    
    // Send pageview
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const handleInputChange = (field) => (e) => {
    if (field === 'notificationText') {
      setNotificationText(e.target.value);
    } else if (field === 'email') {
      setEmail(e.target.value);
    }
    setErrors(prev => ({ ...prev, [field]: '' }));
    setIsFormValid(false);

    // Track input change event
    ReactGA.event({
      category: 'Form Interaction',
      action: `Home: Direct Action Input Change - ${field}`,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let newErrors = { notificationText: '', email: '' };
    let hasError = false;

    if (!notificationText.trim()) {
      newErrors.notificationText = 'Please enter what you want to be notified about';
      hasError = true;
    }

    if (!email.trim()) {
      newErrors.email = 'Please enter your email address';
      hasError = true;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Please enter a valid email address';
      hasError = true;
    }

    setErrors(newErrors);

    if (!hasError) {
      setIsFormValid(true);
      setShowSignIn(false);

      // Asynchronously send the request without waiting for the response
      fetch(`${process.env.REACT_APP_API_URL}/add_home_request`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text: notificationText,
          email: email,
        }),
      }).catch(() => {
        // Silently catch any errors
      });

      // Track form submission event
      ReactGA.event({
        category: 'Form Submission',
        action: 'Home: Try PingPops Now Clicked',
      });

      // Store the pending notification in localStorage
      localStorage.setItem('pendingNotification', JSON.stringify({ text: notificationText, email }));
    }
  };

  const handleSignInClick = () => {
    setShowSignIn(true);
    // Track sign-in button click event
    ReactGA.event({
      category: 'User Authentication',
      action: 'Home: Sign In/Up Button Clicked',
    });
  };

  const handleGoogleLogin = () => {
    setShowSignIn(false); // Reset the sign-in button state
    // Track Google login click event
    ReactGA.event({
      category: 'User Authentication',
      action: 'Home: Google Login Clicked',
    });
  };

  const handleButtonClick = () => {
    if (user) {
      navigate('/console');
    } else {
      handleSignInClick();
    }
  };

  const textFieldSx = {
    mb: 3,
    '& .MuiInputBase-root': {
      height: '56px',
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-20px',
    },
  };

  return (
    <div className="App">
      <header className="App-header">
        <motion.div className="logo" {...FADE_IN}>
          <h1>PingPops</h1>
        </motion.div>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
        >
          keeps you in the loop about what matters to you
        </motion.p>
      </header>

      <main>
        <section className="how-it-works">
          <motion.h2 {...FADE_IN} transition={{ delay: 0.6 }}>
            How It Works
          </motion.h2>
          <div className="steps">
            {[
              { title: "Specify", text: "Tell us what you want to track" },
              { title: "Sit Back", text: "We'll monitor it for you" },
              { title: "Get Notified", text: "Receive notifications when it happens" }
            ].map((step, index) => (
              <Step key={index} {...step} delay={0.8 + index * 0.2} />
            ))}
          </div>
        </section>

        <section className="examples">
          <motion.h2 {...FADE_IN} transition={{ delay: 1.4 }}>
            What Can You Do with PingPops?
          </motion.h2>
          <motion.div
            className="example-cards"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.6, duration: 0.5, staggerChildren: 0.1 }}
          >
            {[
              { 
                title: "Major Events", 
                desc: "Stay informed about significant happenings worldwide", 
                examples: [
                  "2028 LA Olympics opening ceremony tickets go on sale",
                  "Cathedrale Notre-Dame reopens to the public"
                ]
              },
              { 
                title: "Content Updates", 
                desc: "Know instantly when your favorite creator posts", 
                examples: [
                  "New video from MrBeast on YouTube",
                  "Elon Musk tweets about a new SpaceX launch"
                ]
              },
              { 
                title: "Trend Alerts", 
                desc: "Stay on top of market changes and real-time updates", 
                examples: [
                  "NVIDIA stock price surpasses $150 per share",
                  "Air quality index in Los Angeles falls below 50 (Good)"
                ]
              },
              { 
                title: "Endless Possibilities ...", 
                desc: "Be creative and track anything you can imagine", 
                examples: [
                ]
              }
            ].map((card, index) => (
              <Card key={index} {...card} />
            ))}
          </motion.div>
        </section>

        <motion.section
          className="cta"
          {...FADE_IN}
          transition={{ delay: 1.8 }}
        >
          <h2>Ready to Stay Informed?</h2>
          <motion.div className="notification-form-container">
            <AnimatePresence mode="wait">
              {!showSignIn ? (
                <motion.button
                  key="sign-in-button"
                  className="sign-in-button"
                  onClick={handleButtonClick}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  {user ? "Go to My Dashboard" : "Sign In / Sign Up"}
                </motion.button>
              ) : (
                <motion.div
                  key="google-sign-in"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <Box sx={{ mb: 2.5 }}>
                    <LogInButton onClick={handleGoogleLogin} />
                  </Box>
                </motion.div>
              )}
            </AnimatePresence>
            {!user && (
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Notify me when..."
                  variant="outlined"
                  value={notificationText}
                  onChange={handleInputChange('notificationText')}
                  error={!!errors.notificationText}
                  helperText={errors.notificationText}
                  sx={textFieldSx}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <NotificationsActiveIcon sx={iconSx} />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  fullWidth
                  label="Your email"
                  variant="outlined"
                  type="email"
                  value={email}
                  onChange={handleInputChange('email')}
                  error={!!errors.email}
                  helperText={errors.email}
                  sx={textFieldSx}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon sx={iconSx} />
                      </InputAdornment>
                    ),
                  }}
                />
                <AnimatePresence mode="wait">
                  {!isFormValid ? (
                    <motion.div
                      key="submit-button"
                      initial={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <Button
                        className="try-it-button"
                        type="submit"
                        variant="contained"
                        onClick={handleSubmit}
                      >
                        Try PingPops Now!
                      </Button>
                    </motion.div>
                  ) : (
                    <motion.div
                      key="login-options"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.3 }}
                    >
                      <Typography variant="body1" sx={{ mb: 1 }}>Login to confirm your notification:</Typography>
                      <LogInButton onClick={handleGoogleLogin} />
                    </motion.div>
                  )}
                </AnimatePresence>
              </form>
            )}
          </motion.div>
          <motion.a
            href="#plans"
            className="plans-link"
            onClick={togglePlans}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            View Plans
          </motion.a>
        </motion.section>

        <AnimatePresence>
          {showPlans && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Plans />
            </motion.div>
          )}
        </AnimatePresence>
      </main>

      <footer>
        <div className="footer-content">
          <p>&copy; {new Date().getFullYear()} PingPops. All rights reserved.</p>
          <Button
            variant="text"
            color="inherit"
            onClick={() => navigate('/privacy-policy')}
            className="privacy-policy-button"
          >
            Privacy Policy
          </Button>
        </div>
      </footer>
    </div>
  );
}

export default Home;
